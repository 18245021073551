import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { backgroundColored, fontDark } from '@constants/colors';
import { address, details } from '@constants/company-info';
import { navigation, policy, socials } from '@constants/footer-data';
import { laptop, smallTablet } from '@constants/media-queries';
import { contentWidth } from '@constants/sizes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Input from '@elements/Input';

const Footer = () => {
  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit } = formMethods;

  const onSubmit = (data: { newsletter: string }) => {
    console.log('data', data);
  };

  const renderNavigation = useMemo(
    () =>
      navigation.map((section, i) => (
        <Section key={i}>
          <SectionName>{section.label}</SectionName>
          <ul>
            {section.links.map((link, index) => {
              return (
                <li key={index}>
                  {link.external ? (
                    <a href={link.href} target="_blank" rel="noreferrer">
                      {link.label}
                    </a>
                  ) : (
                    <Link href={link.href}>{link.label}</Link>
                  )}
                </li>
              );
            })}
          </ul>
        </Section>
      )),
    [],
  );

  const renderSocials = useMemo(
    () =>
      socials.map((section, i) => (
        <Section key={i}>
          <SectionName>{section.label}</SectionName>
          <Icons>
            {section.links.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  aria-label={link.label}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={link.icon} />
                </a>
              </li>
            ))}
          </Icons>
        </Section>
      )),
    [],
  );

  const renderPolicy = useMemo(
    () =>
      policy.map((link, i) => (
        <li key={i}>
          {link.external ? (
            <a href={link.href} target="_blank" rel="noopener noreferrer">
              {link.label}
            </a>
          ) : (
            <Link href={link.href} passHref>
              <a>{link.label}</a>
            </Link>
          )}
        </li>
      )),
    [],
  );

  return (
    <Component>
      <Container>
        <Navigation>
          <ul>
            {renderNavigation}
            {renderSocials}
          </ul>
        </Navigation>

        {/* <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SectionName>Blijf op de hoogte</SectionName>
            <div>
              <Input
                type="email"
                name="newsletter"
                placeholder="E-mail adres"
                showError
                required
              />
              <Button type="submit">Schrijf mij in</Button>
            </div>
          </Form>
        </FormProvider> */}
      </Container>
      <small>
        {details.website}, {address.address}, {address.zipcode} {address.city}
        <br />© {new Date().getFullYear()} {details.website}
        <Legal>{renderPolicy}</Legal>
      </small>
    </Component>
  );
};

export default Footer;

const Component = styled.footer`
  color: ${backgroundColored};
  background-color: ${fontDark};
  padding: ${space * 8}px ${space * 4}px ${space * 4}px;

  li {
    list-style: none;
  }

  a {
    line-height: 32px;
    &:hover {
      color: white;
    }
  }

  button {
    flex-shrink: 0;
  }

  small {
    display: block;
    text-align: center;
  }
`;

const Container = styled.div`
  max-width: ${contentWidth};
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  text-align: center;

  a {
    text-decoration: none;
  }

  ${smallTablet} {
    text-align: left;
  }
`;

const Navigation = styled.nav`
  flex-grow: 1;
  flex-basis: 100%;

  ${laptop} {
    flex-basis: auto;
  }

  & > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Form = styled.form`
  margin: 0 auto;
  ${smallTablet} {
    margin: 0;
  }

  & > div {
    display: flex;
  }
`;

const SectionName = styled.p`
  color: ${backgroundColored};
  font-weight: bold;
  margin-bottom: 10px;
`;

const Section = styled.li`
  margin-bottom: ${space * 5}px;
  flex-basis: 100%;

  ${smallTablet} {
    flex-basis: 33%;
  }
`;

const Icons = styled.ul`
  display: flex;
  justify-content: center;

  ${smallTablet} {
    justify-content: flex-start;
  }
  & > li {
    margin-right: ${space}px;
  }

  & a {
    padding: 10px 14px;
    font-size: 24px;
    ${laptop} {
      padding: 3px;
    }
  }
`;

const Legal = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > li:not(:last-of-type) {
    margin-right: ${space * 3}px;
  }

  & a {
    text-decoration: underline;
  }
`;
